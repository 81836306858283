import basic from './ru/basic';
import declension from './ru/declension.js';
import route from './ru/route.js';

export default {
    test: 'Тест',
    basic,
    route,
    declension,
};
