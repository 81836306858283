import en from './en.js';
import ru from './ru.js';

export default defineI18nConfig(() => ({
    // strategy: 'no_prefix',
    legacy: false,
    lazy: true,
    locale: 'ru',
    messages: {
        en,
        ru,
    }
}));
